.fadein {
    opacity: 0;
    transition: opacity 0.45s ease-out, transform 0.45s ease-out;
}

.fadein.fade-in {
    opacity: 1;
}


.watermarkfadein {
    opacity: 0;
    transition: opacity 0.45s ease-out, transform 0.45s ease-out, filter 0.45s ease-out;
    filter: blur(0.05em); 
}

.watermarkfadein.watermarkfade-in {
    opacity: 1;
    filter: blur(0);
}

@media (max-width: 530px) {
    .watermarkfadein {
        font-size: 4rem !important;
    }
}

@media (max-height: 550px) {
    .watermarkfadein {
        font-size: 4rem !important;
    }
}