.custom-link {
    text-decoration: none;
    color: white;
    position: relative;
    display: inline-block;
    padding-bottom: 0.25em
}

.custom-link::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.05em;
    background: white;
    bottom: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
    border-radius: 1em;
}

.custom-link:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.span:hover{
    cursor: pointer;
}

/* card */
@media (max-width: 400px) {
    .homeCard {
        height: 6.5rem !important;
        width: 13rem !important;
    }
}

@media (max-height: 500px) {
    .homeCard {
        height: 6.5rem !important;
        width: 13rem !important;
    }
}

/* text in the card */
@media (max-width: 400px) {
    .homeh1 {
        font-size: 1.1rem !important;
    }
}

@media (max-height: 500px) {
    .homeh1 {
        font-size: 1.1rem !important;
    }
}

/* see more button */
@media (max-width: 400px) {
    .custom-link {
        font-size: 1.1rem !important;
    }
}

@media (max-height: 500px) {
    .custom-link {
        font-size: 1.1rem !important;
    }
}