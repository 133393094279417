
.tooltip {
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 110%; 
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .button-container:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
  