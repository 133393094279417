@media (max-width: 1600px) {
    .card {
        height: 25rem !important;
        width: 40rem !important;
    }
}

@media (max-height: 850px) {
    .card {
        height: 22rem !important;
        width: 40rem !important;
    }
}

.link {
    text-decoration: none;
    color: black;
    position: relative;
    display: inline-block;
    padding-bottom: 0.25em
}

.link::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.05em;
    background: black;
    bottom: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
    border-radius: 1em;
}

.link:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.span:hover{
    cursor: pointer;
}