
.custom-cursor {
  position: fixed;
  width: 10em;
  height: 10em;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.8) 0%, rgba(28, 29, 34, 0) 70%);
  pointer-events: none;
  transform: translate(-50%, -50%);
  z-index: 9999;
  opacity: 10%;
}

@media (max-width: 400px) {
  .custom-cursor {
    opacity: 0%;
  }
}

@media (max-height: 850px) {
  .custom-cursor {
    opacity: 0%;
  }
}

